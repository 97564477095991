import React from "react";
import { Link } from "react-router-dom";
import styles from "./footer.module.css";
import MMlogo from "../../assets/images/logo/marketing-manchester-gc-logo-white.svg";
import GClogo from "../../assets/images/gc-logo-white.png";
import LVEPlogo from "../../assets/images/local_visitor_economy_partnership_logo_white.jpeg"

export const Footer = () => {

    let year = new Date().getFullYear();

    return (
        <div className={styles.footerContainer}>
            <section>
                <img style={{ margin: '0 0 50px 0' }} className={styles.logo} src={MMlogo} alt="Marketing Manchester" />
                <div className={styles.sectionFooterContainer}>
                    <div className={`${styles.footerContent1}`}>
                        <h3 style={{ color: 'white' }}>Company Details</h3>
                        <p style={{ color: 'white' }}>
                            Marketing Manchester Ltd<br></br>
                            Lee House<br></br>
                            90 Great Bridgewater Street<br></br>
                            Manchester<br></br>
                            M1 5JW<br></br>
                            <br></br>
                            <small>
                                Registered in England No:&nbsp;03192592<br></br>
                                VAT No:&nbsp;727102071 
                            </small>
                        </p>
                    </div>
                    <div className={`${styles.footerContent2}`}>
                        <h3 style={{ color: 'white' }}>Resources</h3>
                        {/*<Link as={Link} to="/resources">*/}
                        {/*    <p className={styles.hoverStyle}>Resource hub</p>*/}
                        {/*</Link>*/}
                        <a href="https://careers.growthco.uk/home.html" target="_blank" rel="noreferrer">
                            <p className={styles.hoverStyle}>Jobs</p>
                        </a>
                        {/*<Link as={Link} to="#" href="#"><p className={styles.hoverStyle}>Tenders</p></Link>*/}
                        <a href="https://platform.crowdriff.com/m/marketing-manchester" target="_blank" rel="noreferrer">
                            <p className={styles.hoverStyle}>Image Library</p>
                        </a>
                        <a href="/one stop tourism intelligence factsheet.pdf" target="_blank" rel="noreferrer">
                            <p className={styles.hoverStyle}>One Stop Intelligence document</p>
                        </a>
                    </div>
                    <div className={`${styles.footerContent3}`}>
                        <h3 style={{ color: 'white' }}>Legal</h3>
                        <Link as={Link} to="/terms-and-conditions">
                            <p className={styles.hoverStyle}>Terms & Conditions</p>
                        </Link>
                        <Link as={Link} to="/accessibility-statement">
                            <p className={styles.hoverStyle}>Accessibility Statement</p>
                        </Link>
                        <Link as={Link} to="/privacy">
                            <p className={styles.hoverStyle}>Privacy Policy</p>
                        </Link>
                        <a href="https://www.growthco.uk/about-us/policies/modern-slavery-and-human-trafficking-statement/" target="_blank" rel="noreferrer">
                            <p className={styles.hoverStyle}>Modern slavery statement</p>
                        </a>
                        <Link as={Link} to="/cookie-policy">
                            <p className={styles.hoverStyle}>Cookie Policy</p>
                        </Link>
                    </div>
                    {/*<div className={`${styles.footerContent4}`}>*/}
                    {/*    <h3 style={{ color: 'white' }}>Proud to be part of</h3>*/}
                    {/*    <img className={styles.logo} src={GClogo} alt="The Growth Company" />*/}
                    {/*</div>*/}
                    <div className={`${styles.footerContent4}`}>
                        <img className={styles.lveplogo} src={LVEPlogo} alt="The Growth Company" />
                    </div>
                </div>
            <div className={styles.footerBottomContainer}>
                <div style={{ textAlign: "left", fontSize: "12px" }}>
                    <p style={{ color: "lightgray" }}>&copy; Marketing Manchester {year}</p>
                </div>
                <div style={{ textAlign: "right", fontSize: "21px", color: "white" }}>
                        {/*<Link className={styles.hoverStyle} as={Link} to="#" href="#"><span className="gcicon-facebook"></span></Link>*/}
                        <a className={styles.hoverStyle} href="https://twitter.com/marketing_mcr" target="_blank" rel="noreferrer">
                            <span className="gcicon-twitter"></span>
                        </a>
                        <a className={styles.hoverStyle} href="https://uk.linkedin.com/company/marketing-manchester" target="_blank" rel="noreferrer">
                            <span className="gcicon-linkedin"></span>
                        </a>
                </div>
            </div>
        </section>
        </div>
    )
};